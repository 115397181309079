.m-title-w-text-btn {
  background: transparent;
}

.m-title-w-text-btn__title {
  margin-bottom: 3rem;
}

.m-title-w-text-btn__text {
  margin-bottom: 3rem;
}
