.l-nav-mobile {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: $space-md ($grid-gutter-width / 2);
  width: 100%;
  height: 100%;
  background-color: $color-primary;
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-time $transition-easing, visibility $transition-time $transition-easing;

  @include breakpoint($breakpoint-lg, max-width) {

    &.is-open {
      opacity: 1;
      visibility: visible;

      .l-nav-mobile__item {
        animation: fade-in-up 0.4s forwards cubic-bezier(0.28, 0, 0.49, 1);

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            animation-delay: 0.1s * $i;
          }
        }
      }
    }
  }
}

.l-nav-mobile__item {
  color: $color-white;
  font-size: 3.4rem;
  font-weight: 300;
  margin-bottom: $space-xs;
  opacity: 0;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: $color-white;
  }

  &.a-button {
    margin-top: $space-xs;
    text-transform: uppercase;
  }
}

.l-nav-mobile__language-switcher__dropdown {

  &.a-dropdown {
    min-width: 10rem;
  }

  .a-dropdown__button {
    border: 0;
    color: $color-white;
    background-color: transparent;

    &:hover {
      color: inherit;
      background-color: transparent;
    }
  }
}
