.o-grid-products {
  background-color: #f8f8f8;
  padding: $space-xl 0 $space-lg2 0;

  @include breakpoint($breakpoint-lg) {
    padding: $space-xxl 0;
  }
}

.o-grid-products__title {
  text-align: center;
  margin-bottom: $space-sm2;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-xl;
  }
}

.o-grid-products__item-wrapper {
  margin-bottom: $space-md;

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint($breakpoint-lg) {
    margin-bottom: 0;
  }
}

.o-grid-products__item {
  width: 100%;
  text-align: center;
}

.o-grid-products__item__image {
  position: relative;
  display: block;
  margin: auto;
  width: 14rem;
  height: 14rem;
  border-radius: 50%;
  background-color: $color-white;
  margin-bottom: $space-xs;
  overflow: hidden;

  @include breakpoint($breakpoint-lg) {
    width: 18rem;
    height: 18rem;
  }

  img {
    position: absolute;
    width: 100%;
    height: auto;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.o-grid-products__item__title {
  font-size: 1.8rem;
  margin-bottom: $space-tiny;

  @include breakpoint($breakpoint-lg) {
    font-size: 4.4rem;
    margin-bottom: $space-xs;
  }
}

.o-grid-products__item__text {
  margin-bottom: $space-xxs;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-sm2;
  }
}

.o-grid-products__item__brand {
  margin-right: $space-xs;
  display: inline-block;
  width: 10rem;
  margin-bottom: $space-xxs;

  @include breakpoint($breakpoint-lg) {
    margin: auto;
    display: block;
    margin-bottom: $space-xxs;
  }

  &:last-child {

    @include breakpoint($breakpoint-lg, max-width) {
      margin-right: 0;
    }
  }
}
