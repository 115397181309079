.o-content-w-list-image {
  padding: $space-xxl 0 $space-xxxl;
  background: #f8f8f8;
}

.o-content-w-list-image__inner {
  margin-bottom: $space-lg;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: 8rem;
  }
}

.o-content-w-list-image__img {
  width: 100%;
  height: auto;
}

.o-content-w-list-image__items-p {
  @include breakpoint($breakpoint-md) {
    padding-left: 0;
  }
}

.o-content-w-list-image__item {
  text-align: left;
  margin-bottom: $space-sm2;

  &:last-child {
    margin-bottom: 0;
  }
}

.o-content-w-list-image__item__title {
  margin-bottom: $space-xxs;
}
