// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.h-overflow-hidden {
  overflow: hidden !important;
}

.h-no-spacing {
  margin: 0 !important;
  padding: 0 !important;
}

.h-hide-mobile {

  @include breakpoint($breakpoint-lg, max-width) {
    display: none !important;
  }
}

.h-loc-us {
  display: none !important;
}
