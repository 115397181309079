.o-cta {
  position: relative;
  background-color: $color-grey;

  &::before {

    @include breakpoint($breakpoint-lg) {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(270deg, rgba(166, 190, 195, 0.18) 54%, $color-secondary-ice 100%);
    }
  }
}

.o-cta__content {
  position: relative;
  z-index: 1;
  text-align: center;
  padding-top: $space-lg;
  padding-bottom: $space-md;

  @include breakpoint($breakpoint-lg) {
    text-align: left;
    padding: $space-xxxl 0;
  }

  .m-title-w-text {

    @include breakpoint($breakpoint-lg, max-width) {
      color: inherit;
    }
  }
}

.o-cta__image {
  display: block;
  width: 100%;
  height: auto;

  @include breakpoint($breakpoint-lg) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
