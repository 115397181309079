// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site.
// -----------------------------------------------------------------------------

.admin-bar {

  .l-header {
    top: 46px;

    @media screen and (min-width: 783px) {
      top: 32px;
    }
  }
}

.l-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: $space-xxs ($grid-gutter-width / 2);
  background-color: $color-white;
  color: $color-primary;
  transition: color $transition-time $transition-easing, box-shadow $transition-time $transition-easing;

  @include breakpoint($breakpoint-md) {
    padding: $space-sm ($grid-gutter-width * 2);
  }

  &.is-nav-open {
    color: $color-white;
  }

  &.is-sticky {
    box-shadow: 2px 2px 10px 0 rgba($color-black, 0.05);
  }

  &.is-reveal {

    .l-header__logo {
      @extend .a-fade-in-up;
      animation-delay: 0.75s;
    }

    .l-header__nav {

      .l-nav__item {
        @extend .a-fade-in-up;

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            animation-delay: 0.75s + ($i * 0.1s);
          }
        }
      }
    }

    .l-header__nav-toggle {
      @extend .a-fade-in-up;
      animation-delay: 0.75s;
    }
  }
}

.l-header__inner {
  display: flex;
  align-items: center;
}

.l-header__logo {
  position: relative;
  z-index: 101;
  max-width: 16rem;
  flex: 0 0 16rem;
  opacity: 0;

  &.is-open {
    color: $color-white;
  }

  @include breakpoint($breakpoint-lg) {
    max-width: 18rem;
    flex: 0 0 18rem;
    color: $color-primary;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;

    path {

      &:first-child {
        fill: currentColor;
      }
    }
  }
}

.l-header__nav {
  width: 100%;
  margin-right: auto;
  margin-left: $space-lg2;

  .l-nav__item {
    opacity: 0;
  }
}

.l-header__nav-toggle {
  position: relative;
  z-index: 101;
  display: block;
  margin-left: auto;
  color: inherit;
  opacity: 0;
  cursor: pointer;

  @include breakpoint($breakpoint-lg) {
    display: none;
  }

  &.is-open {
    display: none;
  }

  svg {

    path {
      stroke: currentColor;
    }
  }
}

.l-header__menu {
  cursor: pointer;
}
