.o-lander__container {
  padding: 0;
}

.o-lander__background {

  @include breakpoint($breakpoint-md) {
    position: sticky;
    top: 0;
    height: 100vh;
    clip-path: ellipse(100% 100% at 0 center);
  }

  img {
    display: block;
    width: 100%;
    height: auto;

    @include breakpoint($breakpoint-md) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.o-lander__content-wrapper {
  margin-top: auto;
  margin-bottom: auto;
}

.o-lander__content {

  @include breakpoint($breakpoint-lg) {
    max-width: 52rem;
  }

  @include breakpoint($breakpoint-xxl) {
    max-width: 64rem;
  }
}

.o-lander__inner {
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
  padding-bottom: $space-xl;
  padding-top: 4rem;

  @include breakpoint($breakpoint-md) {
    padding-top: $space-xl;
    padding-left: 0;
    padding-right: $grid-gutter-width;
  }

  @include breakpoint($breakpoint-lg) {
    padding-right: $grid-gutter-width / 2;
  }
}

.o-lander__title {
  margin-bottom: $space-xs;
  font-weight: 500;
  color: $color-black;
  font-size: 3.2rem;
  line-height: 3.2rem;
  text-align: center;

  @include breakpoint($breakpoint-lg) {
    max-width: 40rem;
    font-size: 9rem;
    line-height: 7.8rem;
    margin-bottom: $space-sm;
    text-align: left;
    letter-spacing: -0.5px;
  }
}

.o-lander__text {
  color: $color-black;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 300;
  text-align: center;
  margin-bottom: $space-xs;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-xs;
    text-align: left;
    width: 90%;
  }
}

.o-lander__notice {
  color: $color-black;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-align: center;
  margin-bottom: $space-xs;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-xs;
    text-align: left;
  }
}

.o-lander__input__items {

  @include breakpoint($breakpoint-lg) {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
  }
}

.o-lander__input {
  display: inline-block;
  margin-bottom: $space-xxs;
  width: 100%;

  @include breakpoint($breakpoint-lg) {
    flex: 1;

    &:first-child {
      margin-right: $space-xxs;
    }

    &:last-child {
      width: 100%;
      flex: 0 0 100%;
    }
  }
}

.o-lander__brands {

  @include breakpoint($breakpoint-xl) {
    display: flex;
    align-items: flex-start;
  }
}

.o-lander__brands__item {
  text-align: center;
  margin-bottom: $space-sm;

  @include breakpoint($breakpoint-xl) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
    text-align: left;
    margin-bottom: $space-tiny;
    margin-right: $space-sm;

    &--has-1 {
      flex-grow: 0;
      min-width: 12rem;

      .o-lander__brands__item__image {
        max-width: 75%;
        flex: 0 0 75%;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }
}

.o-lander__brands__item__title {
  display: block;
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
  color: $color-secondary-ice;
  line-height: 1.8rem;
  margin-bottom: $space-xs;

  @include breakpoint($breakpoint-md) {
    font-size: 1.5rem;
    margin-bottom: $space-xxs;
  }

  @include breakpoint($breakpoint-xl) {
    text-align: left;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.o-lander__brands__item__image {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.2rem;
  margin-bottom: $space-xs;

  @include breakpoint($breakpoint-xl) {
    flex: 0 0 45%;
    max-width: 45%;
    margin-right: 0;

    &:nth-child(2n + 1) {
      margin-left: 10%;
    }
  }

  img {
    display: block;
    width: auto;
    height: 2.6rem;

    @include breakpoint($breakpoint-xl) {
      width: 100%;
      height: auto;
      max-height: 3.2rem;
    }

    @include breakpoint(1700px) {
      max-height: 4rem;
    }
  }
}

.o-lander__form-wrapper {
  position: relative;

  &.is-success {

    .o-lander__form-success {
      opacity: 1;
      visibility: visible;
    }
  }

  &.is-error {

    .o-lander__form-error {
      opacity: 1;
      visibility: visible;
    }
  }
}

.o-lander__form {
  margin-bottom: $space-lg;
}

.o-lander__form__button-wrapper {
  text-align: center;
  margin-top: $space-tiny;

  @include breakpoint($breakpoint-lg) {
    text-align: left;
  }
}

.o-lander__form-success,
.o-lander__form-error {
  position: absolute;
  left: 0;
  bottom: -4rem;
  width: 100%;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  font-size: 1.8rem;
  transition: opacity $transition-time $transition-easing, visibility $transition-time $transition-easing;
  text-align: center;

  @include breakpoint($breakpoint-lg) {
    text-align: left;
  }
}

.o-lander__form-success {
  color: $color-success;
}

.o-lander__form-error {
  color: $color-error;
}
