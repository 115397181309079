.o-content-product-w-icon {
  margin: $space-xl 0;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-huge;
  }
}

.o-content-product-w-icon--layout-right {

  @include breakpoint($breakpoint-lg) {

    .o-content-product-w-icon__inner {
      flex-direction: row-reverse;
    }
  }
}

.o-content-product-w-icon__title {
  text-align: center;
  margin-bottom: $space-lg;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-xl;
  }
}

.o-content-product-w-icon__inner {
  align-items: flex-end;
}

.o-content-product-w-icon__image {
  width: 100%;
  height: auto;
  margin-bottom: $space-sm;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: 0;
  }
}

.o-content-product-w-icon__item {
  text-align: left;
}

.o-content-product-w-icon__item__icon {
  width: 100%;
  margin-top: $space-xs;
  text-align: center;

  @include breakpoint($breakpoint-lg) {
    text-align: left;
    margin-top: $space-sm2;
  }

  img {
    width: auto;
    height: 3.2rem;

    @include breakpoint($breakpoint-xl) {
      height: 4rem;
    }
  }
}
