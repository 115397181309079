// -----------------------------------------------------------------------------
// This file contains all styles related to the main navigation of the site.
// -----------------------------------------------------------------------------

.l-nav {
  color: $color-black;
  display: none;

  @include breakpoint($breakpoint-lg) {
    display: flex;
    align-items: center;
  }
}

.l-nav__item {
  position: relative;
  display: inline-block;
  margin-right: 3rem;
  padding: $space-tiny/2 0;

  &:last-child {
    margin-right: 0;
  }

  &:not(div) {
    font-size: 1.3rem;
    font-weight: 300;
    letter-spacing: 0.1rem;
  }

  &.a-button {
    min-width: 0;
    text-transform: uppercase;
    padding-top: $space-tiny * 1.5;
    padding-bottom: $space-tiny * 1.5;
  }
}

.l-nav__item--right {
  margin-left: auto;

  svg {
    width: 1.6rem;
  }
}

.l-nav__language-switcher {

  & + .l-nav__item--right {
    margin-left: 0;
  }
}

.l-nav__language-switcher__dropdown {

  &.a-dropdown {
    min-width: 10rem;
  }

  .a-dropdown__button {
    border: 0;
    color: $color-grey;

    &:hover {
      color: inherit;
    }
  }
}
