.gform_wrapper {

  ul.gform_fields {

    li.gfield {
      margin-top: $space-xs;

      input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
      textarea,
      select {
        @extend .a-input;
      }

      .ginput_complex {

        &.ginput_container_name {

          & > span:not(:first-child) {

            input {
              margin-top: 0 !important;
            }
          }

          input,
          textarea,
          select {

            @media only screen and (min-width: 641px) {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
            }
          }
        }

        &.ginput_container_address {

          @media only screen and (min-width: 641px) {
            display: flex;
          }

          .ginput_left,
          .ginput_right {

            @media only screen and (min-width: 641px) {
              width: (100% / 3);

              &:nth-child(2) {
                margin-right: 8px;
              }
            }

            input,
            textarea,
            select {

              @media only screen and (min-width: 641px) {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
              }
            }
          }
        }
      }
    }
  }


  .gform_button {
    @extend .a-button;
    border: 0;

    &[type="submit"] {
      @extend .a-button--primary;
    }
  }

  .field_sublabel_hidden_label {

    .ginput_complex.ginput_container {

      input[type="text"],
      select {
        margin-bottom: 0;
      }
    }
  }

  div.validation_error {
    font-size: 1.6rem;
    font-weight: 400;
    color: $color-error;
    border-color: $color-error;
  }

  .validation_message {
    font-size: 1.3rem;
    font-weight: 400;
    color: $color-error;
  }
}
