.o-resources {
  margin-bottom: $space-xl;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-xxl;
  }
}


.o-resources__item {
  margin-bottom: $space-lg;

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint($breakpoint-md) {
    margin-bottom: $space-sm2;
  }

  @include breakpoint($breakpoint-lg) {
    margin-bottom: 0;
  }

  .m-card-webinar,
  .m-card-article {
    height: 100%;
  }
}

.o-resources__item--webinars {

  .o-resources__carousel-footer {

    @include breakpoint($breakpoint-lg) {
      padding-left: $space-lg;
      padding-right: $space-lg;
    }
  }
}

.o-resources__carousel-wrapper {

  @include breakpoint($breakpoint-lg) {
    height: 100%;
  }
}

.o-resources__carousel-container {

  @include breakpoint($breakpoint-lg) {
    height: 100%;
  }
}

.o-resources__carousel {

  @include breakpoint($breakpoint-lg) {
    height: calc(100% - 6rem);
  }
}

.o-resources__carousel-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $space-sm2;

  @include breakpoint($breakpoint-md) {
    justify-content: flex-start;
    margin-top: 0;
    height: 6rem;
    padding: $space-sm;
    padding-top: 0;
    background-color: $color-secondary-brush-light;
  }
}

.o-resources__carousel-navigation {
  display: none;

  @include breakpoint($breakpoint-md) {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.o-resources__carousel-navigation__item {
  margin-right: $space-xs;
  color: $color-grey;
  transition: color $transition-time $transition-easing;

  &:hover {
    color: $color-secondary-brush;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    display: block;
    width: 3rem;
    height: auto;

    path {
      fill: currentColor;
    }
  }
}
