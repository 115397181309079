.m-card {
  background: $color-secondary-brush-light;
  border-radius: 26px;
  padding: $space-sm;

  @include breakpoint($breakpoint-lg) {
    padding: $space-md $space-sm;
  }
}

.m-card__header {
  width: 100%;

  @include breakpoint(1700px) {
    display: flex;
    justify-content: space-between;
  }
}

.m-card__header__img {
  width: auto;
  height: 4rem;
}

.m-card__header__title {
  text-align: left;
  font-size: 1.6rem;
  font-weight: 400;
  color: $color-secondary-brush;
  margin-top: $space-md;

  @include breakpoint($breakpoint-lg) {
    margin-top: $space-xs;
  }

  @include breakpoint(1700px) {
    margin-top: 0;
    text-align: right;
    font-size: 1.8rem;
    margin-left: $space-xxs;
  }
}

.m-card__content {
  margin-top: $space-xxs;

  @include breakpoint($breakpoint-lg) {
    margin-top: $space-xs;
  }

  @include breakpoint(1700px) {
    margin-top: $space-md;
  }
}

.m-card__content__title {
  font-weight: 300;
  font-size: 1.6rem;
  color: $color-secondary-brush;
  margin-bottom: 0;
  line-height: 1.25;


  @include breakpoint($breakpoint-lg) {
    font-weight: 400;
    font-size: 2.5rem;
    margin-bottom: $space-xxs;
    line-height: 1;
  }
}

.m-card__content__text {
  font-weight: 300;
  font-size: 1.6rem;
  color: $color-secondary-brush;
  line-height: 24px;
}
