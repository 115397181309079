.o-content-w-cards {
  display: flex;
  padding: $space-lg 0 $space-xl 0;

  @include breakpoint($breakpoint-lg) {
    padding: $space-xxl 0 $space-xl 0;
  }
}

.o-content-w-cards__content {
  margin-bottom: $space-lg;
}

.o-content-w-cards__title {
  text-align: center;
  font-weight: 500;
  line-height: 1.09;
  color: $color-secondary-brush;
  margin-bottom: $space-xs;
}

.o-content-w-cards__text {
  text-align: center;
  margin-bottom: $space-lg;
}

.o-content-w-cards__filters {
  display: none;

  @include breakpoint($breakpoint-md) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: $space-xxs;
  }

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-lg;
  }
}

.o-content-w-cards__filter {
  flex: 0 1 0;
  color: $color-secondary-ice;
  text-align: center;
  opacity: 0.5;
  transition: opacity $transition-time $transition-easing;

  &:hover {
    opacity: 1;
  }

  &.is-active {
    text-decoration: underline;
    opacity: 1;
  }
}

.o-content-w-cards__items-wrapper {
  margin-bottom: $space-lg;

  @include breakpoint($breakpoint-md) {
    display: none;
    margin-bottom: 0;

    &.is-active {
      display: block;
    }
  }
}

.o-content-w-cards__items-title {
  margin-bottom: $space-xxs;
  font-size: 2.8rem;
  font-weight: 500;
  color: $color-secondary-ice;
  line-height: 1.15;
  letter-spacing: -0.05rem;
  text-align: center;

  @include breakpoint($breakpoint-md) {
    display: none;
  }
}

.o-content-w-cards__item-wrapper {
  margin-bottom: $grid-gutter-width / 2;

  @include breakpoint($breakpoint-md) {
    margin-bottom: $grid-gutter-width;
  }
}

.o-content-w-cards__item {
  height: 100%;

  .m-card,
  .m-card-product {
    height: 100%;
  }
}
