// -----------------------------------------------------------------------------
// This file contains all @font-face declarations.
// -----------------------------------------------------------------------------

// GT Eesti Display
@font-face {
  font-family: 'GT Eesti Display';
  font-weight: 300;
  font-display: swap;
  src:
    url('../fonts/gt-eesti-display/gt-eesti-display-light.woff2') format('woff2'),
    url('../fonts/gt-eesti-display/gt-eesti-display-light.woff') format('woff');
}

@font-face {
  font-family: 'GT Eesti Display';
  font-weight: 400;
  font-display: swap;
  src:
    url('../fonts/gt-eesti-display/gt-eesti-display-regular.woff2') format('woff2'),
    url('../fonts/gt-eesti-display/gt-eesti-display-regular.woff') format('woff');
}

@font-face {
  font-family: 'GT Eesti Display';
  font-weight: 500;
  font-display: swap;
  src:
    url('../fonts/gt-eesti-display/gt-eesti-display-medium.woff2') format('woff2'),
    url('../fonts/gt-eesti-display/gt-eesti-display-medium.woff') format('woff');
}

// Apercu Mono
@font-face {
  font-family: 'Apercu Mono';
  font-weight: 400;
  font-display: swap;
  src:
    url('../fonts/apercu-mono/apercu-mono-regular.woff2') format('woff2'),
    url('../fonts/apercu-mono/apercu-mono-regular.woff') format('woff');
}
