.s-page {
  @include clearfix;

  h1 {
    margin-bottom: $space-sm;
  }

  h2 {
    margin-bottom: $space-xs;
  }

  h3 {
    margin-bottom: $space-xs;
  }

  h4 {
    margin-bottom: $space-xxs;
  }

  h5 {
    margin-bottom: $space-tiny;
  }

  h6 {
    margin-bottom: $space-tiny;
  }

  p {
    margin-bottom: $space-xs;
    font-size: 1.6rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
