.o-carousel-block {
  padding-bottom: $space-sm2;

  @include breakpoint($breakpoint-lg) {
    padding-bottom: $space-xxxl;
  }
}

.o-carousel-block__content {
  text-align: center;

  @include breakpoint($breakpoint-lg) {
    text-align: left;
  }
}

.o-carousel-block__title {
  margin-bottom: $space-sm;
  margin-top: $space-md;

  @include breakpoint($breakpoint-lg) {
    margin-top: 0;
  }
}

.o-carousel-block__image {

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
