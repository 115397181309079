.m-modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.75);
  }
}

.m-modal-video__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 120rem;
  background: $color-black;
}

.m-modal-video__close {
  position: absolute;
  top: $space-xs;
  right: $space-xs;
  z-index: 2;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  color: $color-white;

  svg {
    display: block;
    width: 100%;
    height: auto;

    path {
      fill: currentColor;
    }
  }
}

.m-modal-video__video {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  background: $color-black;
  box-shadow: 2px 2px 10px 0 rgba($color-black, 0.05);

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}
