// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

html {
  -webkit-font-smoothing: antialiased;
  font-size: 10px;

  @include breakpoint($breakpoint-xxxl) {
    font-size: 12px;
  }
}

body {
  overflow-x: hidden;
  font-family: $font-primary;
  font-size: 1.2rem;
  font-weight: 300;
  color: $color-secondary-brush;

  @include breakpoint($breakpoint-md) {
    font-size: 1.6rem;
  }
}

::selection {
  background: rgba($color-primary, 0.1);
}

a {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

p {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.5;

  @include breakpoint($breakpoint-md) {
    font-size: 1.6rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
      color: $color-black;
    }
  }
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: none;
  -webkit-appearance: none;

  &:not(:focus-visible) {
    outline: 0;
  }
}

.container {

  @include breakpoint($breakpoint-lg) {
    max-width: 87.5%;
  }
}

.container-fluid {

  @include breakpoint($breakpoint-lg) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}

ul,
ol {
  margin: 0;
  padding-left: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-align-center {
  text-align: center !important;
}
