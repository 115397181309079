.m-title-w-text {
  text-align: center;

  @include breakpoint($breakpoint-lg) {
    text-align: left;

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }
  }
}

.m-title-w-text--primary {

  .m-title-w-text__title {
    color: $color-primary;
  }
}

.m-title-w-text--primary-alt {

  .m-title-w-text__subtitle {
    color: $color-primary;
  }

  .m-title-w-text__button {
    @extend .a-button--primary;
  }
}

.m-title-w-text--light {
  color: $color-white;
}

.m-title-w-text__subtitle {
  margin-bottom: $space-tiny;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
}

.m-title-w-text__title {
  margin-bottom: $space-xxs;

  @include breakpoint($breakpoint-md) {
    margin-bottom: $space-xs;
  }
}

.m-title-w-text__button-wrapper {
  margin-top: $space-xs;
}
