.o-grid-cards__items-wrapper {
  display: none;

  @include breakpoint($breakpoint-md) {
    display: block;
  }

  .o-grid-cards__item {
    margin-bottom: $grid-gutter-width;
  }
}

.o-grid-cards__carousel-wrapper {

  @include breakpoint($breakpoint-md) {
    display: none;
  }

  .o-grid-cards__item {
    height: auto;
  }
}

.o-grid-cards__item {

  .m-card,
  .m-card-product {
    height: 100%;
  }
}
