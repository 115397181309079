.m-card-article {
  background-color: $color-secondary-brush-light;
}

.m-card-article__background {
  position: relative;
  display: block;
}

.m-card-article__img {
  display: block;
  width: 100%;
  height: auto;
}

.m-card-article__type {
  padding-bottom: $space-xxs;
  font-family: $font-mono;
  font-weight: 400;

  @include breakpoint($breakpoint-lg) {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: $space-sm;
    padding-bottom: $space-xs;
    color: $color-white;
  }
}

.m-card-article__content {
  padding: $space-sm;
}

.m-card-article__title {
  display: block;
  margin-bottom: $space-xxs;
  letter-spacing: -0.41px;
}
