.o-hero {
  background-image: linear-gradient(180deg, #f0f0f0 74%, $color-white 100%);

  @include breakpoint($breakpoint-lg) {
    height: calc(100vh - 10rem);
  }

  @include breakpoint($breakpoint-lg) {
    padding-left: $grid-gutter-width;
  }
}

.o-hero__container {
  height: 100%;
}

.o-hero__row {
  flex-direction: column-reverse;
  height: 100%;

  @include breakpoint($breakpoint-lg) {
    flex-direction: row;
  }
}

.o-hero__image-wrapper {
  height: 100%;
  margin-bottom: $space-xxs;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: 0;
  }
}

.o-hero__image {
  @include stretch('both');
  position: relative;
  clip-path: circle(70% at 50% 10%);

  @include breakpoint($breakpoint-lg) {
    @include stretch('right');
    margin-left: 0;
    height: 100%;
    clip-path: ellipse(100% 100% at 100% 0);
  }

  img {
    display: block;
    width: 100%;
    height: auto;

    @include breakpoint($breakpoint-lg) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  video {
    display: none;

    @include breakpoint($breakpoint-lg) {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.o-hero__content {

  .m-title-w-text__title {
    font-size: 5.2rem;

    @include breakpoint($breakpoint-lg) {
      font-size: 7rem;
    }
  }
}
