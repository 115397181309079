// -----------------------------------------------------------------------------
// This file contains base typography styles.
// -----------------------------------------------------------------------------

@for $i from 1 through 6 {
  h#{$i} {
    margin: 0;
  }

  .h#{$i} {
    font-weight: 500;
    line-height: 1;

    @include breakpoint($breakpoint-lg) {
      letter-spacing: -0.05rem;
    }

    b {
      font-weight: 700;
    }

    em {
      display: inline-block;
      position: relative;
      font-style: normal;

      &::after {
        content: '';
        position: relative;
        top: 0.2rem;
        display: block;
        width: 100%;
        height: 0.4rem;
        background-color: currentColor;
      }
    }
  }
}

.h1 {
  font-size: 3.2rem;

  @include breakpoint($breakpoint-lg) {
    font-size: 9rem;
  }
}

.h2 {
  font-size: 3.2rem;

  @include breakpoint($breakpoint-lg) {
    font-size: 6.4rem;
    line-height: 1.1;
  }
}

.h3 {
  font-size: 3.2rem;

  @include breakpoint($breakpoint-lg) {
    font-size: 4.8rem;
    line-height: 1.125;
  }
}

.h4 {
  font-size: 1.8rem;

  @include breakpoint($breakpoint-lg) {
    font-size: 3.4rem;
    line-height: 1.25;
  }
}

.h5 {
  font-size: 1.6rem;
  line-height: 1.5;

  @include breakpoint($breakpoint-lg) {
    font-size: 2.8rem;
    line-height: 1.2;
  }
}

.h6 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.25;

  @include breakpoint($breakpoint-lg) {
    font-size: 1.8rem;
  }
}
