.o-contact-form {
  margin: $space-lg2 0;

  @include breakpoint($breakpoint-lg) {
    margin: $space-xxl 0;
  }
}

.o-contact-form__inner {

  @include breakpoint($breakpoint-lg) {
    max-width: 72rem;
    margin: auto;
  }
}

.o-contact-form__form-wrapper {

  .a-input {
    margin-bottom: $space-sm;
  }

  .gform_confirmation_message {
    @extend .h6;
    text-align: center;
  }
}
