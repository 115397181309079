.m-carousel-images__carousel-wrapper {
  position: relative;
  margin-bottom: $space-sm2;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: 0;
  }
}

.m-carousel-images__carousel__item {
  position: relative;
  overflow: hidden;
}

.m-carousel-images__carousel__item__image {
  display: block;
  width: 100%;
  height: auto;
}

.m-carousel-images__carousel__item__title {
  position: absolute;
  top: 25%;
  left: 5%;
  width: 90%;
  padding: $space-xs $space-sm;
  background: $color-white;
  box-shadow: 0 2px 12px 0 rgba($color-black, 0.07);
  border-radius: 26px;

  @include breakpoint($breakpoint-lg) {
    width: 80%;
    padding: $space-sm $space-sm2;
    left: 12%;
  }
}

.m-carousel-images__carousel__item__text {
  position: absolute;
  left: 5%;
  bottom: 0;
  width: 90%;
  padding: $space-xs 0;
  font-weight: 500;
  color: $color-white;

  @include breakpoint($breakpoint-lg) {
    padding: $space-sm $space-sm2;
    padding-right: $space-xl;
  }

  p {
    font-weight: inherit;
  }
}

.m-carousel-images__carousel-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: $space-sm;

  @include breakpoint($breakpoint-lg) {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: $space-sm;
    margin-top: 0;
  }

  .swiper-pagination-bullet {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: $space-tiny;
    border-radius: 50%;
    cursor: pointer;
    background-color: $color-grey;
    transition: background-color $transition-time $transition-easing;

    @include breakpoint($breakpoint-lg) {
      margin-right: $space-tiny / 2;
    }

    &:hover {
      background-color: lighten($color-primary, 20%);
    }

    &:last-child {
      margin-right: 0;
    }

    &.swiper-pagination-bullet-active {
      background-color: $color-primary;
    }
  }
}
