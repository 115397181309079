.o-leadership-list {
  margin: $space-xl 0;

  @include breakpoint($breakpoint-lg) {
    margin: $space-xxxl 0;
  }
}

.o-leadership-list__header {

  .m-title-w-text {
    text-align: center;
  }
}

.o-leadership-list__items-wrapper {
  margin-top: $space-md;

  @include breakpoint($breakpoint-lg) {
    margin-top: $space-xl;
  }
}

.o-leadership-list__item {
  text-align: center;
  margin-bottom: $space-xl;

  @include breakpoint($breakpoint-lg) {
    display: flex;
    align-items: center;
    margin-bottom: $space-xxl;
    text-align: left;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.o-leadership-list__item--layout-right {

  @include breakpoint($breakpoint-lg) {
    flex-direction: row-reverse;
  }

  .o-leadership-list__item__image-wrapper {

    @include breakpoint($breakpoint-lg) {
      margin-left: $space-xxl;
      margin-right: 0;
    }
  }
}

.o-leadership-list__item__image-wrapper {
  width: 100%;
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $space-xs;

  @include breakpoint($breakpoint-lg) {
    max-width: 36rem;
    flex: 0 0 36rem;
    margin-left: 0;
    margin-right: $space-xxl;
    margin-bottom: 0;
  }
}

.o-leadership-list__item__image {
  position: relative;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    body:not(.is-ie) & {
      background-color: $color-secondary-ice;
      mix-blend-mode: hard-light;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: saturate(0);
  }
}

.o-leadership-list__item__header {
  margin-bottom: $space-xs;

  @include breakpoint($breakpoint-lg) {
    display: flex;
    align-items: center;
  }
}

.o-leadership-list__item__title {
  margin-bottom: $space-tiny;

  @include breakpoint($breakpoint-lg) {
    margin-right: $space-xs;
    margin-bottom: 0;
  }
}

.o-leadership-list__item__subtitle {
  color: $color-secondary-ice;
}

.o-leadership-list__item__text {
  font-size: 1.4rem;
  line-height: 1.7;
}
