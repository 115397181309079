.o-statement {
  text-align: center;
  margin: 8rem 0;

  @include breakpoint($breakpoint-lg) {
    margin: $space-xxl 0;
    text-align: left;
  }
}

.o-statement__title {
  margin-bottom: $space-sm;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: 0;
  }
}
