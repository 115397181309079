.m-card-webinar {
  height: 100%;
  background-color: $color-secondary-brush-light;
}

.m-card-webinar__img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.m-card-webinar__content {
  padding: $space-sm;

  @include breakpoint($breakpoint-lg) {
    padding-left: $space-lg;
    padding-right: $space-lg;
  }
}

.m-card-webinar__header__title {
  font-family: $font-mono;
  font-weight: 400;
}

.m-card-webinar__header__date {
  font-size: 1.2rem;
  font-weight: 300;
  display: inline-block;
  margin-left: $space-sm;
  line-height: 1.5;

  @include breakpoint($breakpoint-lg) {
    font-size: 1.6rem;
  }
}

.m-card-webinar__inner {
  display: flex;
  flex-wrap: wrap;
  margin-top: $space-xs;

  @include breakpoint($breakpoint-lg) {
    flex-wrap: nowrap;
  }
}

.m-card-webinar__inner__title {
  width: 100%;
  margin-bottom: $space-xs;
  margin-right: $space-lg;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: 0;
  }
}

.m-card-webinar__inner__btns {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @include breakpoint($breakpoint-lg) {
    margin-left: auto;
  }
}

.m-card-webinar__inner__social {
  width: 2.5rem;
  margin-left: $space-xs;

  svg {
    display: block;
    width: 100%;
    height: auto;

    path {
      fill: currentColor;
    }
  }
}
