.a-link {
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  line-height: 1.15;
  text-decoration: underline;
  color: inherit;
  transition: color $transition-time $transition-easing;

  &:hover,
  &:focus {
    color: $color-secondary-ice;
    text-decoration: underline;
  }
}

.a-link--light {
  color: $color-white;

  &:hover,
  &:focus {
    color: $color-secondary-brush-light;
  }
}
