.o-header {
  display: flex;
  align-items: center;
  position: relative;
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
  clip-path: ellipse(120% 100% at 50% -5%);
  min-height: 52rem;
  padding-bottom: $space-md;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(270deg, rgba(166, 190, 195, 0.18) 54%, $color-secondary-ice 100%);
  }

  @include breakpoint($breakpoint-lg) {
    clip-path: ellipse(100% 100% at 50% -5%);
  }
}

.o-header__container {
  position: relative;
  height: 100%;
}

.o-header__content {

  .m-title-w-text {
    text-align: left;
  }

  .m-title-w-text__title {
    font-size: 5.2rem;

    @include breakpoint($breakpoint-lg) {
      font-size: 7rem;
    }
  }
}

.o-content-product-w-icon__carousel-images {

  .m-carousel-images__carousel-wrapper {

    @include breakpoint($breakpoint-lg) {
      padding-bottom: 8rem;
    }
  }
}
