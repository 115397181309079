.o-content-product {
  padding: $space-lg 0;

  @include breakpoint($breakpoint-lg) {
    padding: $space-xxxl 0;
  }
}

.o-content-product__img {
  width: 100%;
  height: auto;
  margin-top: $space-sm;

  @include breakpoint($breakpoint-lg) {
    margin-top: 0;
  }
}
