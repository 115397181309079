.o-grid-articles {
  padding: $space-lg 0;
  background-image: linear-gradient(180deg, #f0f0f0 0%, $color-white 30%);

  @include breakpoint($breakpoint-md) {
    padding: $space-xxl 0;
  }
}

.o-grid-articles__title {
  font-weight: 500;
  text-align: center;
  color: $color-secondary-brush;
  margin-bottom: $space-lg;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-xxl;
  }
}

.o-grid-articles__header {
  display: flex;
  margin-bottom: $space-xs;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-lg;
  }
}

.o-grid-articles__filters {

  @include breakpoint($breakpoint-lg, max-width) {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.o-grid-articles__filters__title {
  margin-right: $space-tiny;
  font-size: 1.6rem;
  letter-spacing: -0.05rem;

  @include breakpoint($breakpoint-lg) {
    display: none;
  }
}

.o-grid-articles__filters__items {
  margin-left: auto;
}

.o-grid-articles__filters__item {
  font-size: 1.8rem;
  font-weight: 400;
  color: $color-secondary-ice;
  opacity: 0.5;
  margin-right: $space-xs;
  transition: opacity $transition-time $transition-easing;

  @include breakpoint($breakpoint-lg) {
    font-size: 2.5rem;
    margin-right: $space-sm2;
  }

  &:hover {
    opacity: 1;
    color: $color-secondary-ice;
  }

  &:last-child {
    margin-right: 0;
  }

  &.is-active {
    opacity: 1;
    text-decoration: underline;
  }
}

.o-grid-articles__sort {
  display: none;

  @include breakpoint($breakpoint-lg) {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.o-grid-articles__sort__title {
  margin-right: $space-tiny;
  font-size: 1.6rem;
  letter-spacing: -0.05rem;
}

.o-grid-articles__item {
  margin-bottom: 2rem;

  @include breakpoint($breakpoint-lg, max-width) {

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $grid-gutter-width;
  }

  .m-card-webinar,
  .m-card-article {
    height: 100%;
  }
}

.o-grid-articles__button {
  margin-top: $space-lg;
  text-align: center;

  @include breakpoint($breakpoint-lg) {
    margin-top: 8rem;
  }
}
