// -----------------------------------------------------------------------------
// This file contains all animations.
// -----------------------------------------------------------------------------

@keyframes header-sticky-in {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.a-header-sticky-in {
  animation-name: header-sticky-in;
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.a-fade-in {
  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 3rem, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.a-fade-in-up {
  animation-name: fade-in-up;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.28, 0, 0.49, 1);
}

@keyframes slide-in-left {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.a-slide-in-left {
  animation-name: slide-in-left;
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.28, 0, 0.49, 1);
}

@keyframes slide-in-right {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.a-slide-in-right {
  animation-name: slide-in-right;
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.28, 0, 0.49, 1);
}

@keyframes slide-in-up {
  from {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.a-slide-in-up {
  animation-name: slide-in-up;
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.28, 0, 0.49, 1);
}

@keyframes slide-in-bottom {
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.a-slide-in-bottom {
  animation-name: slide-in-bottom;
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.28, 0, 0.49, 1);
}
