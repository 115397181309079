// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@mixin breakpoint($point, $min-or-max: min-width) {
  @if $min-or-max == max-width {
    $point: $point - 1;
  }

  @media ($min-or-max: $point) {
    @content;
  }
}

@mixin placeholder {

  &::-webkit-input-placeholder { @content; }
  &:-moz-placeholder           { @content; }
  &::-moz-placeholder          { @content; }
  &:-ms-input-placeholder      { @content; }
}

@mixin clearfix {

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin backdrop($position: before) {
  position: relative;

  &::#{$position} {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.25);
  }
}

@mixin stretch-styles($side, $container-width: 0) {
  $gutter: $grid-gutter-width / 2;
  $value: -$gutter;

  @if $container-width != 0 {
    $value: calc(((-100vw + #{$container-width}) / 2) - #{$gutter});
  }

  @if $side == 'left' {
    margin-left: $value;
  } @else if $side == 'right' {
    margin-right: $value;
  } @else {
    margin-left: $value;
    margin-right: $value;
  }
}

@mixin stretch($side) {
  @include stretch-styles($side, 0);

  @include breakpoint($breakpoint-sm) {
    @include stretch-styles($side, map-get($container-max-widths, sm));
  }

  @include breakpoint($breakpoint-md) {
    @include stretch-styles($side, map-get($container-max-widths, md));
  }

  @include breakpoint($breakpoint-lg) {
    @include stretch-styles($side, 87.5vw);
  }
}

@mixin full-bg-image() {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
