.a-input {
  display: block;
  width: 100%;
  padding: 1.5rem $space-xxs;
  border-radius: 4px;
  box-shadow: none;
  font-size: 1.6rem;
  letter-spacing: 0.1rem;
  border: 1px solid $color-grey;
  color: $color-black;
  -webkit-appearance: none;

  @include placeholder {
    color: $color-grey;
  }

  &:not(:focus-visible) {
    outline: 0;
  }
}

.a-input--transparent {
  background-color: transparent;

  @include placeholder {
    color: $color-black;
  }
}

textarea.a-input {
  min-height: 24rem;
  resize: none;
}
