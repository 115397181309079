.o-content-product-w-cards {
  margin: $space-lg 0;

  @include breakpoint($breakpoint-lg) {
    margin: $space-xxxl 0;
  }
}

.o-content-product-w-cards__content {

  .m-title-w-text__title {

    @media (min-width: $breakpoint-lg) and (max-width: $breakpoint-xl - 1) {
      font-size: 6.4rem;
    }
  }
}

.o-content-product-w-cards__img {
  width: 100%;
  margin-top: $space-sm;
  margin-bottom: $space-lg;

  @include breakpoint($breakpoint-lg) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.o-content-product-w-cards__items-wrapper {
  display: none;
  margin-top: $space-xxl;

  @include breakpoint($breakpoint-md) {
    display: block;
  }

  .o-content-product-w-cards__item {
    margin-bottom: $grid-gutter-width;
  }
}

.o-content-product-w-cards__carousel-wrapper {
  margin-top: $space-lg;

  @include breakpoint($breakpoint-md) {
    display: none;
  }

  .o-content-product-w-cards__item {
    height: auto;
  }
}

.o-content-product-w-cards__item {

  .m-card,
  .m-card-product {
    height: 100%;
  }
}
