.o-content-w-list-video {
  padding: $space-xl 0;

  @include breakpoint($breakpoint-lg) {
    padding: $space-xxxl 0;
  }
}

.o-content-w-list-video__image {
  position: relative;
  display: none;
  margin-bottom: $space-lg2;

  @include breakpoint($breakpoint-lg) {
    display: block;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.o-content-w-list-video__video {
  display: none;

  @include breakpoint($breakpoint-lg) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video {

    @include breakpoint($breakpoint-lg) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
    }
  }
}

.o-content-w-list-video__video__icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  color: $color-primary;
  border: 2px solid;
  border-radius: 2rem;
  padding: $space-xxs $space-sm2;

  svg {
    width: 1.2rem;
    height: auto;
    margin-right: $space-tiny * 1.5;

    path {
      fill: currentColor;
    }
  }

  span {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
  }
}

.o-content-w-list-video__title {
  text-align: center;
  font-weight: 500;
  color: $color-secondary-brush;
  margin-bottom: $space-sm2;

  @include breakpoint($breakpoint-lg) {
    text-align: left;
    margin-bottom: 0;
  }
}

.o-content-w-list-video__item {
  margin-bottom: $space-sm2;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-xs;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.o-content-w-list-video__item__img {
  display: block;
  width: 100%;
  margin-bottom: $space-xxs;

  @include breakpoint($breakpoint-lg) {
    display: none;
    margin-bottom: $space-xxs;
  }
}

.o-content-w-list-video__item__title {
  font-size: 1.8rem;
  color: $color-secondary-brush;
  font-weight: 500;
  margin-bottom: $space-tiny;

  @include breakpoint($breakpoint-lg) {
    color: $color-secondary-ice;
    margin-bottom: $space-xxs;
    font-size: 4.8rem;
  }
}

.o-content-w-list-video__item__text {
  margin-bottom: $space-sm2;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-xxs;
  }
}
