.m-social {
  display: flex;
  align-items: center;
}

.m-social__item {
  width: 1.8rem;
  margin-right: $space-xxs;

  &:last-child {
    margin: 0;
  }

  @include breakpoint($breakpoint-lg) {
    width: 2.2rem;
  }
}

.m-social__item__image {
  display: block;
  width: 100%;
  height: auto;
}
