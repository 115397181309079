.a-dropdown {
  position: relative;
  min-width: 18rem;

  &.is-loaded {

    .a-dropdown__select {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    .a-dropdown__button,
    .a-dropdown__items {
      display: block;
    }
  }

  &.is-open {

    .a-dropdown__indicator {
      transform: rotate(180deg);
    }

    .a-dropdown__items {
      opacity: 1;
      visibility: visible;
    }
  }
}

.a-dropdown__select {
  display: block;
  width: 100%;
}

.a-dropdown__button {
  position: relative;
  display: none;
  width: 100%;
  padding: $space-tiny $space-xxs;
  padding-right: $space-xs;
  font-size: 1.6rem;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid $color-grey;
  background-color: $color-white;
  transition: color $transition-time $transition-easing, background-color $transition-time $transition-easing;
  text-align: left;

  &:hover,
  &:focus {
    background-color: $color-secondary-brush-light;
  }
}

.a-dropdown__indicator {
  position: absolute;
  z-index: 11;
  top: 0;
  right: 0;
  width: $space-xs;
  height: 100%;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.8rem;
    height: auto;
    transform: translate(-50%, -50%);

    path {
      fill: currentColor;
    }
  }
}

.a-dropdown__items {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  border-radius: 4px;
  border: 1px solid $color-grey;
  background-color: $color-white;
  opacity: 0;
  visibility: hidden;

  .a-dropdown--top & {
    bottom: 0;
    top: auto;
  }
}

.a-dropdown__item {
  display: block;
  width: 100%;
  padding: ($space-tiny / 2) $space-xxs;
  padding-right: $space-xs;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
  color: $color-grey;
  transition: color $transition-time $transition-easing;

  &:first-child {
    padding-top: $space-tiny;
  }

  &:last-child {
    padding-bottom: $space-tiny;
  }

  &:hover,
  &.is-active {
    color: $color-secondary-brush;
  }
}
