.a-button {
  position: relative;
  display: inline-block;
  min-width: 15rem;
  padding: $space-xxs $space-xs;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  line-height: 1.3;
  text-align: center;
  background-color: $color-secondary-brush;
  border: 2px solid $color-secondary-brush;
  border-radius: 15px;
  color: $color-white;
  transition: color $transition-time $transition-easing, background-color $transition-time $transition-easing, border-color $transition-time $transition-easing;

  &:hover {
    background-color: transparent;
    border-color: currentColor;
    color: $color-secondary-brush;
  }

  span {
    position: relative;
    z-index: 1;
  }

  @include breakpoint($breakpoint-lg) {
    font-size: 1.4rem;
  }
}

.a-button--primary {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;

  &:hover {
    color: $color-primary;
    border-color: currentColor;
    background-color: transparent;
  }
}

.a-button--light {
  color: $color-primary;
  background-color: $color-white;
  border-color: $color-white;

  &:hover {
    color: $color-white;
    border-color: currentColor;
    background-color: transparent;
  }
}

.a-button--outline {
  background-color: transparent;
  border-color: currentColor;
  color: $color-secondary-brush;

  &:hover {
    border-color: $color-black;
    background-color: $color-black;
    color: $color-white;
  }

  &.a-button--primary {
    color: $color-primary;

    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-white;
    }
  }
}
