.m-card-product {
  background: $color-secondary-ice;
  background-position: bottom right;
  background-size: auto 65%;
  background-repeat: no-repeat;
  flex-wrap: wrap;
  border-radius: 26px;
  padding: $space-md $space-sm;
}

.m-card-product__inner {

  @include breakpoint($breakpoint-xl) {
    max-width: 65%;
  }

  @include breakpoint(1700px) {
    max-width: 55%;
  }

  .m-title-w-text {
    text-align: left;
  }
}
