.o-leadership-grid {
  margin: $space-xl 0;

  @include breakpoint($breakpoint-lg) {
    margin: $space-xxxl 0;
  }
}

.o-leadership-grid__header {
  margin-bottom: $space-md;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-xl;
  }

  .m-title-w-text {
    text-align: center;
  }
}

.o-leadership-grid__item {
  text-align: center;
  margin-bottom: $space-lg2;

  &:last-child {

    @include breakpoint($breakpoint-lg, max-width) {
      margin-bottom: 0;
    }
  }
}

.o-leadership-grid__item__image-wrapper {
  width: 100%;
  max-width: 24rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $space-xs;

  @include breakpoint($breakpoint-lg) {
    margin-bottom: $space-sm2;
  }
}

.o-leadership-grid__item__image {
  position: relative;
  padding-bottom: 100%;
  border-radius: 50%;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    body:not(.is-ie) & {
      background-color: $color-secondary-ice;
      mix-blend-mode: hard-light;
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: saturate(0);
  }
}

.o-leadership-grid__item__title {
  margin-bottom: $space-tiny;
}

.o-leadership-grid__item__subtitle {
  font-size: 1.4rem;
  color: $color-secondary-ice;

  @include breakpoint($breakpoint-lg) {
    font-size: 1.6rem;
  }
}
