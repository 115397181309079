// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site.
// -----------------------------------------------------------------------------

.l-footer {
  padding: $space-sm2 0;
  background-color: $color-black;
  color: $color-white;

  @include breakpoint($breakpoint-md) {
    padding: $space-lg 0;
  }
}

.l-footer__content {
  margin-right: auto;
}

.l-footer__logo {
  display: block;
  width: 100%;
  max-width: 16.6rem;
  margin-right: auto;
  margin-bottom: 0;
  color: $color-white;

  @include breakpoint($breakpoint-lg) {
    margin-left: 0;
    margin-right: 0;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;

    path {
      fill: currentColor;
    }
  }
}

.l-footer__nav {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.l-footer__nav__items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.l-footer__nav__items--right {
  justify-content: flex-start;
}

.l-footer__nav__social {
  justify-content: flex-end;
}

.l-footer__nav__item {
  display: block;
  font-size: 1.2rem;
  font-weight: 400;
  margin-right: $space-xxs;
  margin-bottom: $space-xxs;

  &:hover {
    color: $color-secondary-ice;
  }

  @include breakpoint($breakpoint-lg) {
    font-size: 1.6rem;
    margin-right: 0;
  }
}

.l-footer__brands {
  padding-top: $space-xxs;
  padding-bottom: $space-xxs;

  @include breakpoint($breakpoint-lg) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.l-footer__brands__item {
  display: block;

  @include breakpoint($breakpoint-lg) {

    &:last-child {
      margin-bottom: $space-xl;
    }
  }
}

.l-footer__nav__item--m {
  margin-bottom: 0;

  @include breakpoint($breakpoint-lg) {
    margin-left: $space-lg;
    margin-right: 0;
  }
}

.l-footer__item {
  display: block;
  width: 100%;
  text-align: left;
  color: $color-white;
  font-size: 1.2rem;
  margin-top: $space-xs;
  margin-bottom: $space-md;

  a {
    text-decoration: underline;

    &:hover {
      color: $color-secondary-ice;
    }
  }

  @include breakpoint($breakpoint-lg) {
    font-size: 1.6rem;
  }
}

.l-footer__social {
  width: 100%;
  justify-content: flex-end;

  @include breakpoint($breakpoint-lg) {
    justify-content: flex-start;
    margin: 0;
  }
}

.l-footer__copyright {
  text-align: left;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: $space-xxs;

  @include breakpoint($breakpoint-lg) {
    font-size: 1.6rem;
    margin-bottom: 0;
  }
}

.l-footer__bottom {
  margin-top: $space-xs;

  @include breakpoint($breakpoint-lg) {
    margin-top: $space-xl;
  }
}
