.a-icon {
  display: block;
  width: 100%;
  height: auto;
  color: inherit;

  path {
    fill: currentColor;
  }
}

.a-icon--stroke {

  path {
    fill: none;
    stroke: currentColor;
  }
}
